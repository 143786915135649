<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">EDIT </span> STUDENT
      INFORMATION
    </PageHeader>
    <v-row justify="center">
      <v-col cols="10">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="subtitle-1">
              Search Student by Index Number
            </v-toolbar-title>

            <v-autocomplete
              @change="studentDetails"
              :loading="loadStudent"
              v-model="index_number"
              :items="studentcampusBasket"
              :search-input.sync="searchStudent"
              @keyup="debouncesearchStudent"
              hide-selected
              cache-items
              hide-details
              flat
              item-text="index_number_title_name"
              item-value="id"
              label="Index Number"
              solo-inverted
              class="mx-4"
            >
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-img
                    :src="data.item.avatar_url"
                    :lazy-src="data.item.avatar_url"
                  >
                    <!-- <template #placeholder>
                      <v-row
                        class="ma-0 fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                        ></v-progress-circular>
                      </v-row>
                    </template> -->
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.index_number_title_name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title v-if="loadStudent">
                    <span class="mr-4">
                      <v-progress-circular
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </span>
                    Searching
                    <strong>Student...</strong>
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    No
                    <strong>Student Found...</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-scroll-y-transition>
      <v-row v-show="ifstudentFound" class="mt-6">
        <v-item-group v-model="tab" class="shrink mx-2" mandatory tag="v-flex">
          <v-card>
            <v-card-text>
              <v-item
                v-for="(item, index) in steps"
                :key="index"
                v-slot="{ active, toggle }"
              >
                <div>
                  <v-btn
                    depressed
                    class="ma-1"
                    :input-value="active"
                    :class="active ? 'primary font-weight-bold' : null"
                    @click="toggle"
                  >
                    {{ item.name }}
                  </v-btn>
                </div>
              </v-item>
            </v-card-text>
          </v-card>
        </v-item-group>

        <v-col cols="12" lg="9" class="white elevation-2 rounded">
          <v-window v-model="tab" class="elevation-0" vertical>
            <v-window-item eager v-for="(item, i) in steps" :key="i">
              <component
                :is="item.component"
                v-bind="{ studentId: getters_student_details }"
              ></component>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-scroll-y-transition>
  </v-container>
</template>
<script>
  import { defineComponent, getCurrentInstance, provide, reactive, ref, toRefs } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Personal from "@/components/Student/Create/Personal";
  import SchoolInformation from "@/components/Student/Create/SchoolInformation";
  import EmergencyInformation from "@/components/Student/Create/EmergencyInformation";
  import Documents from "@/components/Student/Create/Documents";
  export default defineComponent({
    components: {
      PageHeader,
      Personal,
      SchoolInformation,
      EmergencyInformation,
      Documents,
    },

    setup() {
      const vm = getCurrentInstance()
      const {
        getStudentCampus,
        getStudentDetails,
        deleteStudentRecord,
        signOut,
      } = useActions([
        "getStudentCampus",
        "getStudentDetails",
        "updateStudentRecord",
        "deleteStudentRecord",
        "signOut",
      ]);

      const { getters_student_campus, getters_student_details } = useGetters([
        "getters_student_campus",
        "getters_student_details",
      ]);

      const searchStudentHeader = reactive({
        delStudentDialog: false,
        loadStudent: false,
        index_number: null,
        studentcampusBasket: [],
        searchStudent: null,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        successFeedback: false,
        actionResponse: false,
      });

      const {
        searchStudent,
        studentcampusBasket,
        loadStudent,
        msgHeader,
        msgBody,
        msgIcon,
        actionResponse,
        successFeedback,
        index_number,
      } = toRefs(searchStudentHeader);

      let ifstudentFound = ref(false);

      let studentForm = reactive({
        steps: [
          { name: "Personal Information", component: "Personal", valid: true },
          {
            name: "School Information",
            component: "SchoolInformation",
          },
          {
            name: "Emergency Information",
            component: "EmergencyInformation",
          },
          {
            name: "Documents",
            component: "Documents",
          },
        ],
        tab: null,
        absolute: true,
        overlay: false,
      });

      const {
        //grade,
        overlay,
        id,
      } = toRefs(studentForm);

      const studentDetails = (index_number) => {
        overlay.value = true;
        getStudentDetails(index_number)
          .then(() => {
            overlay.value = false;
            ifstudentFound.value = true;
          })
          .catch((e) => {
            if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            }
          });
      };

      const debouncesearchStudent = debounce(() => {
        if (searchStudent.value) {
          // if (studentcampusBasket.value.length > 0) return;
          loadStudent.value = true;

          getStudentCampus(searchStudent.value)
            .then(() => {
              studentcampusBasket.value = getters_student_campus.value;
            })
            .finally(() => {
              loadStudent.value = false;
            })
            .catch((e) => {
              if (e.response.status === 423) {
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
              }
            });
        }
      });

      const deletestudentRecordBtn = () => {
        actionResponse.value = false;
        overlay.value = true;
        deleteStudentRecord(id.value)
          .then(() => {
            successFeedback.value = true;
            msgHeader.value = "Success";
            msgIcon.value = "mdi-check-circle";
            msgBody.value = "Saved";
            overlay.value = false;
          })
          .catch((e) => {
            overlay.value = false;
            actionResponse.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            if (e.response.status === 403) {
              msgBody.value = `Student Cannot Be Deleted. Student's Entry Level and Current Level Is Different`;
            } else if (e.response.status === 422) {
              msgBody.value = `Student Cannot Be Deleted. Because There Are Result(s)
                                                                                                                    In The Student's Scripts`;
            } else if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            }
          });
      };

      provide("user", getters_student_details);

      provide("indexNumber", index_number);

      return {
        studentDetails,
        debouncesearchStudent,
        ...toRefs(searchStudentHeader),
        ...toRefs(studentForm),
        ifstudentFound,
        deletestudentRecordBtn,
        getters_student_details,
      };
    },
  });
</script>
